import { Home, Inbox, Mail } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";

import BookIcon from "@mui/icons-material/Book";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import CancelIcon from "@mui/icons-material/Cancel";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import BookmarkIcon from "@mui/icons-material/Bookmark";

import HailIcon from "@mui/icons-material/Hail";

import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import VerifiedIcon from "@mui/icons-material/Verified";

import CommuteIcon from "@mui/icons-material/Commute";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";

import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import NoTransferIcon from "@mui/icons-material/NoTransfer";

import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import HandymanIcon from "@mui/icons-material/Handyman";
import ForumIcon from "@mui/icons-material/Forum";

import FeedbackIcon from "@mui/icons-material/Feedback";

import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";

import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import PlaylistAddCheckCircleIcon from "@mui/icons-material/PlaylistAddCheckCircle";
import ShieldIcon from "@mui/icons-material/Shield";
import DiscountIcon from "@mui/icons-material/Discount";
import TuneIcon from "@mui/icons-material/Tune";
const menuItems = [
  {
    icon: <Home />,
    text: "Home",
    path: "/main/dashboard",
    subMenuItems: [],
  },
  {
    icon: <HowToRegIcon />,
    text: "PreRegistered",
    path: "/main/preregistered",
    subMenuItems: [],
  },
  {
    icon: <BookmarkIcon />,
    text: "Bookings",
    path: "/main/bookings",
    subMenuItems: [
      { icon: <BookIcon />, text: "Live", path: "/main/bookings/live" },
      {
        icon: <ThumbDownIcon />,
        text: "Rejected",
        path: "/main/bookings/rejected",
      },
      {
        icon: <CancelIcon />,
        text: "Cancelled",
        path: "/main/bookings/cancelled",
      },
      {
        icon: <CreditCardOffIcon />,
        text: "Failed",
        path: "/main/bookings/failed",
      },
      {
        icon: <HistoryToggleOffIcon />,
        text: "History",
        path: "/main/bookings/history",
      },
    ],
  },
  {
    icon: <HailIcon />,
    text: "Customers",
    path: "/main/customer",

    subMenuItems: [
      {
        icon: <PersonIcon />,
        text: "Customers List",
        path: "/main/customers/all",
      },
      {
        icon: <PersonOffIcon />,
        text: "Blocked",
        path: "/main/customers/blocked",
      },
    ],
  },
  {
    icon: <ManageAccountsIcon />,
    text: "Hosts",
    path: "/main/host",

    subMenuItems: [
      { icon: <VerifiedIcon />, text: "Approve", path: "/main/host/approve" },
      { icon: <PersonIcon />, text: "Hosts List", path: "/main/host/all" },
      {
        icon: <PersonOffIcon />,
        text: "Blocked",
        path: "/main/host/blocked",
      },
    ],
  },
  {
    icon: <CommuteIcon />,
    text: "Vehicles",
    path: "/main/vehicles",

    subMenuItems: [
      {
        icon: <DriveEtaIcon />,
        text: "New Vehicles",
        path: "/main/vehicles/verify",
      },
      {
        icon: <ShareLocationIcon />,
        text: "AssignGPS",
        path: "/main/vehicles/assigngps",
      },
      {
        icon: <DriveEtaIcon />,
        text: "LiveVehicles",
        path: "/main/vehicles/list",
      },

      {
        icon: <GpsFixedIcon />,
        text: "Tracking",
        path: "/main/vehicles/tracking",
      },
      {
        icon: <NoTransferIcon />,
        text: "Blocked",
        path: "/main/vehicles/blocked",
      },
    ],
  },
  {
    icon: <SupportAgentIcon />,
    text: "Support",
    path: "/main/support",

    subMenuItems: [
      {
        icon: <ForumIcon />,
        text: "LiveChat",
        path: "/main/support/livechat",
      },
      {
        icon: <HandymanIcon />,
        text: "Mechanic",
        path: "/main/support/mechanic",
      },
    ],
  },
  {
    icon: <FeedbackIcon />,
    text: "Complaints",
    path: "/main/complaints",

    subMenuItems: [],
  },
  {
    icon: <LocalTaxiIcon />,
    text: "Vehicle Admin",
    path: "/main/vadmin",

    subMenuItems: [
      {
        icon: <HandymanIcon />,
        text: "Make",
        path: "/main/vadmin/vehiclemake",
      },
      {
        icon: <HandymanIcon />,
        text: "Model",
        path: "/main/vadmin/vehiclemodel",
      },
      {
        icon: <HandymanIcon />,
        text: "Category",
        path: "/main/vadmin/vehiclecategory",
      },
      {
        icon: <LocalGasStationIcon />,
        text: "FuelType",
        path: "/main/vadmin/vehicleFuelType",
      },
      {
        icon: <HandymanIcon />,
        text: "Trasmission",
        path: "/main/vadmin/vehicletransmission",
      },
      {
        icon: <PlaylistAddCheckCircleIcon />,
        text: "Features",
        path: "/main/vadmin/vehiclefeatures",
      },
    ],
  },
  {
    icon: <TuneIcon />,
    text: "App Admin",
    path: "/main/aadmin",

    subMenuItems: [
      {
        icon: <DiscountIcon />,
        text: "Coupons",
        path: "/main/aadmin/coupons",
      },
      {
        icon: <ShieldIcon />,
        text: "Protection Plans",
        path: "/main/aadmin/protectionplans",
      },
    ],
  },
];

export default menuItems;
