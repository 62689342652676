import React from "react";

import { TextField, Button, Paper, Grid2 } from "@mui/material";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import LinearProgress from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import CookiesServices from "../src/services/CookiesServices";
import { useState } from "react";
import AuthServices from "../src/services/AuthServices";
import CustomLoader from "../src/pages/components/CustomLoader";
import CustomAlert from "../src/pages/components/CustomAlert";

const LoginPage = () => {
  //localStorage.clear();
  //CookiesServices.destroy();
  const navigate = useNavigate();
  const [response, setResponse] = useState();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [showProgress, setShowProgress] = React.useState(false);

  const handleLogin = async () => {
    setShowProgress(true);
    if (username.length > 0 && password.length > 0) {
      // Call API to login
      let data = await AuthServices.ValidateUser({
        username: username,
        password: password,
      });
      console.log("Response data>", data);
      if (data.status == true) {
        let remember = true;
        setResponse({ severity: "success", message: data.message });
        AuthServices.handleLoginSuccess(data.result, remember);
        setTimeout(() => {
          navigate("/main");
        }, 500);
      } else {
        setResponse({ severity: "error", message: data.message });
      }
    } else {
      setResponse({
        severity: "error",
        message: "Please enter username and password",
      });
    }
    setShowProgress(false);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Grid2
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
        >
          <Grid2 item xs={6}>
            <Paper
              elevation={3}
              style={{ padding: 20, margin: 20, textAlign: "center" }}
            >
              {response && (
                <CustomAlert
                  severity={response.severity}
                  message={response.message}
                />
              )}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Avatar
                  alt="Logo"
                  src=" 
https://objectstorage.ap-hyderabad-1.oraclecloud.com/n/axhsu9endsms/b/testbharatcars/o/bcp-images%2FB.png"
                  sx={{ width: 80, height: 80, margin: "0 auto 20px" }}
                />
              </div>
              <Typography variant="h5">Bharat Self Drive Cars</Typography>
              <Typography variant="body1">
                Please enter your username and password to login.
              </Typography>

              <TextField
                label="Username"
                variant="outlined"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                fullWidth
                margin="normal"
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLogin}
                >
                  Submit
                </Button>
              </div>
            </Paper>
          </Grid2>
          <Grid2 item xs={6}></Grid2>
        </Grid2>
      </div>
      {showProgress && (
        <LinearProgress
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
          }}
        />
      )}
    </>
  );
};

export default LoginPage;
