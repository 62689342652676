//generate component for Dashboard just display Message Dashboar at center of the page
import React from "react";
import { Typography } from "@mui/material";
const Dashboard = () => {
  return (
    <>
      <Typography variant="h4">Dashboard</Typography>
    </>
  );
};
export default Dashboard;
