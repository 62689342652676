import CookiesServices from "./CookiesServices";

import HttpService from "./HttpService";
import axios from "axios";
import { apiEndpoint } from "../config";

let axiosConfig = {
  withCredentials: true,
};

class VehicleServices {
  constructor() {
    const token = CookiesServices.get("token");
    token ? (this.authenticated = true) : (this.authenticated = false);
    this.http = new HttpService();
  }
  async getAllVehiclesDetails() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/vehicle/all`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getVehicleFeatures(vehicleid) {
    try {
      const response = await axios.get(
        `${apiEndpoint}/vehiclefeatures/` + vehicleid,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getVehiclePricing(vehicleid) {
    try {
      const response = await axios.get(
        `${apiEndpoint}/hostpricing/vehicle/` + vehicleid,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getVehicleImages(vehicleid) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/documents/getDetails`,
        {
          type: "VehicleImage",
          vehicleid: vehicleid,
        },
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getAllNewVehicles() {
    try {
      const response = await axios.post(
        `${apiEndpoint}/vehicle/all`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async updateVehicleVerification(vehicleid, status, value) {
    try {
      let params = {};
      if (status == "basic") {
        params = {
          vehicleid: vehicleid,
          basicVerified: value,
        };
      }
      if (status == "features") {
        params = {
          vehicleid: vehicleid,
          featuresVerified: value,
        };
      }
      if (status == "pricing") {
        params = {
          vehicleid: vehicleid,
          pricingVerified: value,
        };
      }
      if (status == "images") {
        params = {
          vehicleid: vehicleid,
          imagesVerified: value,
        };
      }
      if (status == "host") {
        params = {
          vehicleid: vehicleid,
          hostVerified: value,
        };
      }
      if (status == "location") {
        params = {
          vehicleid: vehicleid,
          locationVerified: value,
        };
      }
      if (status == "description") {
        params = {
          vehicleid: vehicleid,
          descriptionVerified: value,
        };
      }
      const response = await axios.post(
        `${apiEndpoint}/vehicle/updateverification`,
        params,
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getVehicleVerificationInfo(id) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/vehicle/getverification`,
        { vehicleid: id },
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getUnVerifiedVehicles() {
    try {
      const response = await axios.post(
        `${apiEndpoint}/vehicle/allUnVerified`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getVerifiedVehicles() {
    try {
      const response = await axios.post(
        `${apiEndpoint}/vehicle/allVerified`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async getVerifiedVehiclesWithNoGPS() {
    try {
      const response = await axios.post(
        `${apiEndpoint}/vehicle/allVerifiednogps`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
  async enableGps(vehicleid, hostid, gpsuniqueid) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/vehicle/enablegps`,
        {
          id: vehicleid,
          hostid: hostid,
          isgpsenable: true,
          gpsuniqueid: gpsuniqueid,
        },
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getLiveVehiclesList() {
    try {
      const response = await axios.get(
        `${apiEndpoint}/livevehicle`,
        {},
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  async getLocationOfVehicleId(id) {
    try {
      const response = await axios.post(
        `${apiEndpoint}/livevehicle/location`,
        { id },
        axiosConfig
      );
      return response.data;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }
}

export default new VehicleServices();
