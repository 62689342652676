import * as React from "react";
import { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import Chip from "@mui/material/Chip";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import UserServices from "../../services/UserServices";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import VehicleServices from "../../services/VehicleServices";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { NewReleases } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const ApprovalPage = () => {
  const location = useLocation();
  const [imageUrl, setImageUrl] = useState("");
  const [hostInfo, setHostInfo] = useState({});
  const [profileurl, setProfileUrl] = useState("");
  const [isAadharVerified, setIsAadharVerified] = useState(false);
  const [isDLVerified, setIsDLVerified] = useState(false);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [vehicleImages, setVehicleImages] = useState([]);
  const [vehiclePricing, setVehiclePricing] = useState([]);
  const [vehicleFeatures, setVehicleFeatures] = useState([]);
  const [vehicleVerification, setVehicleVerification] = useState([]);
  const [messageOfApi, setMessageOfApi] = useState("");

  const { selectedRow } = location.state;
  const [open, setOpen] = useState(false);
  const [allFieldsVerified, setAllFieldsVerified] = useState(false);
  const [dontShowImageApproveButton, setDontShowImageApproveButton] =
    useState(false);
  const [dontShowHostApproveButton, setDontShowHostApproveButton] =
    useState(false);

  const [dontShowFeaturesApproveButton, setDontShowFeaturesApproveButton] =
    useState(false);
  const [dontShowPriceApproveButton, setDontShowPriceApproveButton] =
    useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    if (!open) {
      // Your code to execute after the dialog is closed
      console.log("Dialog closed");
      handleGetUserData();
      handleGetVehicelImages();
      handleVehiclePricing();
      handleVehicleFeatures();
      handleVerifyVehicleDetails();
    }
  }, [open]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleGetVehicelImages = async () => {
    const vehicleImages = await VehicleServices.getVehicleImages(
      selectedRow.id
    );
    if (vehicleImages.status == true) {
      setVehicleImages(vehicleImages.result);
      vehicleImages.result.map((image) => {
        console.log("Vehicle Image:=>", image.url);
        if (image.url == null) {
          setDontShowImageApproveButton(true);
        }
      });
    }
    console.log("vehicleImages:=>", vehicleImages);
  };
  const handleVehiclePricing = async () => {
    const vehiclePricingRow = await VehicleServices.getVehiclePricing(
      selectedRow.id
    );
    console.log("vehiclePricingRow:=>", JSON.stringify(vehiclePricingRow));
    if (vehiclePricingRow.status == true) {
      if (vehiclePricingRow.result[0].hostPricing.length > 0)
        setVehiclePricing(vehiclePricingRow.result[0]);
      else {
        console.log("No Pricing Found");
        setVehiclePricing([]);
        dontShowPriceApproveButton(true);
      }
    }
    console.log("Vehicle Pricing:=>", JSON.stringify(vehiclePricing));
  };
  const handleVehicleFeatures = async () => {
    const vehicleFeatures = await VehicleServices.getVehicleFeatures(
      selectedRow.id
    );
    console.log("vehicleFeatures:=>", vehicleFeatures);
    if (vehicleFeatures.status == true) {
      if (vehicleFeatures.result.length > 0)
        setVehicleFeatures(vehicleFeatures.result);
      else {
        console.log("No Features Found");
        setDontShowFeaturesApproveButton(true);
      }
    } else {
      console.log("No Features Found");
      setVehicleFeatures([]);
    }
  };

  const handleVerifyVehicleDetails = async () => {
    const vehicleVerification =
      await VehicleServices.getVehicleVerificationInfo(selectedRow.id);
    console.log("Vehicle Verification:" + JSON.stringify(vehicleVerification));
    if (vehicleVerification.status == true) {
      setVehicleVerification(vehicleVerification.result);
      //Generate a code to verify each element of result and find Fields with ending with Verified and count and display count

      //Check if all the fields are verified
      if (
        vehicleVerification.result.basicVerified &&
        vehicleVerification.result.hostVerified &&
        vehicleVerification.result.imagesVerified &&
        vehicleVerification.result.featuresVerified &&
        vehicleVerification.result.pricingVerified &&
        vehicleVerification.result.descriptionVerified &&
        vehicleVerification.result.locationVerified
      ) {
        console.log("All Fields Verified");
        setAllFieldsVerified(true);
      } else {
        console.log("All Fields Not Verified");
        setAllFieldsVerified(false);
      }
    }
  };

  const handleBasicReject = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "basic",
      0
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Basic Info Rejected Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Basic Info Rejected Successfully");
      handleClickOpen();
    }
  };

  const handleBasicApprove = async () => {
    console.log("selectedRow.id: In Basic Approve =>", selectedRow.id);
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "basic",
      1
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Basic Info Approved Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Basic Info Approved Successfully");
      handleClickOpen();
    }
  };

  const handleHostReject = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "host",
      0
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Host Rejected Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Host Rejected Successfully");
      handleClickOpen();
    }
  };
  const handleHostApprove = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "host",
      1
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Host Approved Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Host Approved Successfully");
      handleClickOpen();
    }
  };

  const handleImagesReject = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "images",
      0
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Images Rejected Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Images Rejected Successfully");
      handleClickOpen();
    }
  };
  const handleImagesApprove = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "images",
      1
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Images Approved Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Images Approved Successfully");
      handleClickOpen();
    }
  };

  const handleFeaturesReject = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "features",
      0
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Features Rejected Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Features Rejected Successfully");
      handleClickOpen();
    }
  };
  const handleFeaturesApprove = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "features",
      1
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Features Approved Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Features Approved Successfully");
      handleClickOpen();
    }
  };

  const handlePricingReject = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "pricing",
      0
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Pricing Rejected Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Pricing Rejected Successfully");
      handleClickOpen();
    }
  };
  const handlePricingApprove = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "pricing",
      1
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Pricing Approved Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Pricing Approved Successfully");
      handleClickOpen();
    }
  };

  const handleVehicleDescriptionReject = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "description",
      0
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Description Rejected Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Description Rejected Successfully");
      handleClickOpen();
    }
  };
  const handleVehicleDescriptionApprove = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "description",
      1
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Description Approved Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Description Approved Successfully");
      handleClickOpen();
    }
  };

  const handleLocationReject = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "location",
      0
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Location Rejected Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Location Rejected Successfully");
      handleClickOpen();
    }
  };
  const handleLocationApprove = async () => {
    const vehicleVerification = await VehicleServices.updateVehicleVerification(
      selectedRow.id,
      "location",
      1
    );
    console.log("vehicleVerification:=>", vehicleVerification);
    if (vehicleVerification.status == true) {
      //generate a code to show dilog box to display successfully updated message
      console.log("Vehicle Location Approved Successfully");
      //Display a dialog box to show the success message
      setMessageOfApi("Vehicle Location Approved Successfully");
      handleClickOpen();
    }
  };

  const formatRupee = (amount) => {
    const formattedNumber = amount.toLocaleString("en-IN");
    return `\u20B9${formattedNumber}`;
  };

  const handleGetUserData = async () => {
    console.log("selectedRow.hostid:=>", selectedRow.hostid);
    const userData = await UserServices.getUserById(selectedRow.hostid);
    console.log("userData:=>", userData);
    if (userData.status == true) {
      setHostInfo(userData.result);
      setProfileUrl(userData.result.profilePicture);
      setIsAadharVerified(userData.result.isAadharVerified);
      setIsDLVerified(userData.result.isDlVerified);
      setIsMobileVerified(userData.result.isMobileVerified);
      setIsEmailVerified(userData.result.isEmailVerified);
      if (
        !userData.result.isMobileVerified ||
        !userData.result.isAadharVerified
      ) {
        setDontShowHostApproveButton(true);
      }
    }
  };

  useEffect(() => {
    console.log("Received selectedRow:", selectedRow);
    if (selectedRow.category == "Hatchback") {
      setImageUrl(
        "https://objectstorage.ap-hyderabad-1.oraclecloud.com/n/axhsu9endsms/b/testbharatcars/o/category%2Fhatchback.png"
      );
    }
    if (selectedRow.category == "Sedan") {
      setImageUrl(
        "https://objectstorage.ap-hyderabad-1.oraclecloud.com/n/axhsu9endsms/b/testbharatcars/o/category%2Fsedan.png"
      );
    }
    if (selectedRow.category == "SUV") {
      setImageUrl(
        "https://objectstorage.ap-hyderabad-1.oraclecloud.com/n/axhsu9endsms/b/testbharatcars/o/category%2Fsuv.png"
      );
    }
    if (selectedRow.category == "MUV") {
      setImageUrl(
        "https://objectstorage.ap-hyderabad-1.oraclecloud.com/n/axhsu9endsms/b/testbharatcars/o/category%2Fmuv.png"
      );
    }
    console.log("selectedRow.profilePicture:=>" + selectedRow.profilePicture);

    handleGetUserData();
    handleGetVehicelImages();
    handleVehiclePricing();
    handleVehicleFeatures();
    handleVerifyVehicleDetails();
  }, [selectedRow]);
  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "16px",
      }}
    >
      <div style={{ flex: 1, textAlign: "left" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: 2,
          }}
        >
          <Typography
            variant="h6"
            id="tableTitle"
            component="div"
            sx={{ marginBottom: 2 }}
          >
            <strong>Approval of {selectedRow.vehicleno}</strong>
          </Typography>
          {allFieldsVerified ? (
            <Chip
              variant="outlined"
              color="success"
              label="Verified"
              icon={<VerifiedIcon />}
            />
          ) : (
            <Chip
              variant="outlined"
              color="warning"
              label="Not Verified"
              icon={<NewReleases />}
            />
          )}
        </div>

        <div style={{ display: "flex", gap: "16px", margin: 2 }}>
          <Card
            sx={{
              flex: 1,
              marginLeft: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CardMedia
              sx={{ height: 150 }} // Adjusted height
              image={imageUrl}
              title="Vehicle Image"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {selectedRow.vehicleno}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {selectedRow.make} &#x2022; {selectedRow.model} &#x2022;{" "}
                {selectedRow.color}
                <br />
                {selectedRow.category} &#x2022; {selectedRow.fueltype} &#x2022;{" "}
                {selectedRow.transmission}
                <br />
                {selectedRow.vehicleseatingcapacity} Seater &#x2022;{" "}
                {selectedRow.registrationyear} Registered <br />
                {selectedRow.vehiclekms} Kms
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end", marginTop: "auto" }}>
              {vehicleVerification.basicVerified ? (
                <Button size="small" onClick={handleBasicReject}>
                  Reject
                </Button>
              ) : (
                <Button size="small" onClick={handleBasicApprove}>
                  Approve
                </Button>
              )}
            </CardActions>
          </Card>

          <Card
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CardMedia
              sx={{ height: 150 }} // Adjusted height
              image={profileurl}
              title="Host Profile Image"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {hostInfo.name}
              </Typography>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", textAlign: "left" }}
                >
                  {hostInfo.mobile}
                </Typography>

                {isMobileVerified ? (
                  <VerifiedIcon
                    color="success"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                ) : (
                  <NewReleasesIcon
                    color="warning"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", textAlign: "left" }}
                >
                  {hostInfo.email}
                </Typography>
                {isEmailVerified ? (
                  <VerifiedIcon
                    color="success"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                ) : (
                  <NewReleasesIcon
                    color="warning"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                )}
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", textAlign: "left" }}
                >
                  Aadhar Card
                </Typography>
                {isAadharVerified ? (
                  <VerifiedIcon
                    color="success"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                ) : (
                  <NewReleasesIcon
                    color="warning"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", textAlign: "left" }}
                >
                  Driving License
                </Typography>
                {isDLVerified ? (
                  <VerifiedIcon
                    color="success"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                ) : (
                  <NewReleasesIcon
                    color="warning"
                    sx={{
                      height: "20px",
                      textAlign: "right",
                      width: "20px",
                    }}
                  />
                )}
              </div>
            </CardContent>

            <CardActions sx={{ justifyContent: "flex-end", marginTop: "auto" }}>
              {vehicleVerification.hostVerified ? (
                <Button size="small" onClick={handleHostReject}>
                  Reject
                </Button>
              ) : !dontShowHostApproveButton ? (
                <Button size="small" onClick={handleHostApprove}>
                  Approve
                </Button>
              ) : null}
            </CardActions>
          </Card>

          <Card
            sx={{
              maxWidth: 345,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Carousel
              showArrows={true}
              autoPlay={true}
              infiniteLoop={true}
              showThumbs={false} // Removed Thumbs
            >
              {vehicleImages.map((image, index) => (
                <div key={index}>
                  {image.url ? (
                    <img
                      src={image.url}
                      alt={`Vehicle ${index + 1}`}
                      style={{
                        height: "260px",
                        width: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div>
                      <CloudUploadIcon
                        style={{ fontSize: "100px", color: "gray" }}
                      />
                      <br />
                      No Image Uploaded
                    </div>
                  )}

                  <Typography
                    gutterBottom
                    component="div"
                    sx={{ textAlign: "left", marginTop: 2, marginLeft: 2 }}
                  >
                    {image.documenttypename}
                  </Typography>
                </div>
              ))}
            </Carousel>
            {/* <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Pricing -{" "}
                {vehiclePricing && vehiclePricing.hostPricing
                  ? vehiclePricing.hostPricing[0].rateperhour + "/Hr"
                  : "Not Available"}
              </Typography>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="body2"
                  sx={{ color: "text.secondary", textAlign: "left" }}
                >
                  Extra Hour Charges:{" "}
                  {vehiclePricing && vehiclePricing.hostPricing
                    ? vehiclePricing.hostPricing[0].extrarateperhour + "/Hr"
                    : "Not Available"}
                </Typography>
              </div>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", textAlign: "left" }}
              >
                Available On:{" "}
                {vehiclePricing && vehiclePricing.hostPricing
                  ? vehiclePricing.hostPricing[0].day
                  : "Not Available"}
              </Typography>
            </CardContent> */}

            <CardActions sx={{ justifyContent: "flex-end", marginTop: "auto" }}>
              {vehicleVerification.imagesVerified ? (
                <Button size="small" onClick={handleImagesReject}>
                  Reject
                </Button>
              ) : !dontShowImageApproveButton ? (
                <Button size="small" onClick={handleImagesApprove}>
                  Approve
                </Button>
              ) : null}
            </CardActions>
          </Card>

          <Card
            sx={{
              maxWidth: 345,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Car Features
              </Typography>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "8px",
                  maxHeight: "245px",
                  overflowY: "auto",
                }}
              >
                {vehicleFeatures.map((feature, index) => (
                  <div
                    key={index}
                    style={{
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={feature.url}
                      alt={`Feature ${index + 1}`}
                      style={{
                        height: "32px", // Adjusted height
                        width: "32px",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                    <Typography
                      gutterBottom
                      component="div"
                      sx={{ marginTop: 1, fontSize: "0.875rem" }} // Reduced font size
                    >
                      {feature.feature}
                    </Typography>
                  </div>
                ))}
              </div>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end", marginTop: "auto" }}>
              {vehicleVerification.featuresVerified ? (
                <Button size="small" onClick={handleFeaturesReject}>
                  Reject
                </Button>
              ) : !dontShowFeaturesApproveButton ? (
                <Button size="small" onClick={handleFeaturesApprove}>
                  Approve
                </Button>
              ) : null}
            </CardActions>
          </Card>
        </div>

        <div style={{ display: "flex", gap: "16px", margin: 2 }}>
          <Card
            sx={{
              flex: 1,
              marginLeft: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Pricing Details
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                {vehiclePricing && vehiclePricing.hostPricing
                  ? formatRupee(vehiclePricing.hostPricing[0].rateperhour) +
                    "/Hr"
                  : "Not Available"}
              </Typography>

              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Extra Hour Charges:{" "}
                {vehiclePricing && vehiclePricing.hostPricing
                  ? formatRupee(
                      vehiclePricing.hostPricing[0].extrarateperhour
                    ) + "/Hr"
                  : "Not Available"}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", textAlign: "left" }}
              >
                Available On:{" "}
                {vehiclePricing && vehiclePricing.hostPricing
                  ? vehiclePricing.hostPricing[0].day
                  : "Not Available"}
              </Typography>
              <div style={{ display: "flex", gap: "16px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                ></div>
              </div>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end", marginTop: "auto" }}>
              {vehicleVerification.pricingVerified ? (
                <Button size="small" onClick={handlePricingReject}>
                  Reject
                </Button>
              ) : !dontShowPriceApproveButton ? (
                <Button size="small" onClick={handlePricingApprove}>
                  Approve
                </Button>
              ) : null}
            </CardActions>
          </Card>

          <Card
            sx={{
              flex: 1,
              marginLeft: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Base Location
              </Typography>
              <Typography gutterBottom variant="h5" component="div">
                {selectedRow.basecity}
              </Typography>

              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {selectedRow.baselocality}
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end", marginTop: "auto" }}>
              {vehicleVerification.locationVerified ? (
                <Button size="small" onClick={handleLocationReject}>
                  Reject
                </Button>
              ) : (
                <Button size="small" onClick={handleLocationApprove}>
                  Approve
                </Button>
              )}
            </CardActions>
          </Card>
          <Card
            sx={{
              flex: 1,
              marginLeft: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Vehicle Description
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {selectedRow.description}
              </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end", marginTop: "auto" }}>
              {vehicleVerification.descriptionVerified ? (
                <Button size="small" onClick={handleVehicleDescriptionReject}>
                  Reject
                </Button>
              ) : (
                <Button size="small" onClick={handleVehicleDescriptionApprove}>
                  Approve
                </Button>
              )}
            </CardActions>
          </Card>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {messageOfApi}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ApprovalPage;
